<template>
  <div
    class="page-box-cust-change-model"
    :class="isPcMobileClass()"
  >
    <div class="page-title">
      输入变更后名称
    </div>
    <div class="page-hint">
      不超过 50 个字，须与企业证照保持一致
    </div>
    <div class="page-input">
      <cube-form
        :model="model"
      >
        <cube-form-group>
          <cube-form-item :field="fields[0]">
            <cube-input
              ref="custCn"
              v-model="model.custCn"
              placeholder="请输入"
              maxlength="50"
              :autofocus="true"
            />
          </cube-form-item>
        </cube-form-group>
      </cube-form>
    </div>
    <div
      class="bottom"
    >
      <cube-button
        :disabled="!model.custCn"
        class="btn btnWidth"
        @click="submitHandler"
      >
        下一步
      </cube-button>
    </div>
  </div>
</template>

<script>
import { isPcMobile } from '@/lib/until.js';
export default {
  data() {
    return {
      model: {
        custCn: ''
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'custCn',
          label: '客户名称',
          props: {
            placeholder: '请输入',
            autofocus: true,
            maxlength: 50
          }
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(()=> {
      this.$refs.custCn.focus();
    });
  },
  methods: {
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    },
    submitHandler() {
      this.$router.push(
        {
          name: 'UploadCustfile',
          query: {
            id: this.$route.query.id,
            custCn: this.model.custCn
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss">
.page-box-cust-change-model{
  overflow: hidden;
  background-color: $color-FFF ;
  .cube-form-label{
    width: 84px;
    padding: 0;
    color:#23252E;
    font-size: 15px;
    text-align: left;
  }
  .cube-form-item{
    padding: 0;
  }
  .cube-input-field{
    color: #23252E;
    font-size: 15px;
  }
}
</style>

<style lang="scss"  scoped>
.page-title {
  margin: 48px auto;
  margin-bottom: 16px;
  color: #23252E;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.page-input{
  margin: 0 0 46px  15px;
  padding: 10px 0 4px 0;
  border-bottom:1px solid #EAEBED;
}
.page-hint{
  margin-bottom: 24px;
  color: #23252E;
  font-size: 15px;
  line-height: 20px;
}
</style>
